import React from 'react';
import './TotalAnunturi.css';
import config from './config';

class TotalAnunturi extends React.Component {

    render() {
        return (
            <div>
                <div id="total-anunturi">
                    <div className="totaluri-row">
                        <div className="label">Anunturi total</div>
                        <div className="value">{this.props.anunturi}</div>
                    </div>
                    <div className="totaluri-row">
                        <div className="label">Anunturi azi</div>
                        <div className="value">{this.props.anunturiAzi}</div>
                    </div>
                    <div className="totaluri-row">
                        <div className="label">Licitatii azi</div>
                        <div className="value">{this.props.licitatii}</div>
                    </div>
                </div>
                <div id="versiunea-desktop">Versiunea {config.version}</div>
            </div>
        )
    }
}

export default TotalAnunturi;
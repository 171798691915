import Salvata  from "../Salvata";
import { useNavigate } from "react-router-dom";
import JudeteDataService from "../services/JudeteDataService";

function LicitatieRow({licitatie}) {

    const navigate = useNavigate();

    function handleLicitatieClick() {
        navigate("/id/" + licitatie.id );
    }

    function getCssClassName(licitatieId) {
        let classes = "licitatie-row";
        if ( JudeteDataService.licitatiiAllSelected === licitatieId ) {
            classes += " selected";
        }
        return classes;
    }

    return (
        <div className={getCssClassName(licitatie.id)} onClick={ handleLicitatieClick } >
            <div className='licitatie-id-smaller'>{licitatie.id}</div>
            <div className='all-licitatii-total-price'>{licitatie.total_price}</div> 
            <div>{licitatie.surface}</div> 
            <div>{ parseFloat(licitatie.price_mp).toFixed(2)}</div> 
            <div><Salvata salvata={licitatie.salvata}/></div>
        </div>
    );
}

export default LicitatieRow;
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import './AllLicitatiiJudet.css'
import JudeteDataService from '../services/JudeteDataService';

const ORDER_BY_MP_PRICE = 1;
const ORDER_BY_SURFACE = 2;
const ORDER_BY_TOTAL_PRICE = 3;
const ORDER_BY_ID = 4;

class TotaluriJudet extends React.Component {

    render() {
        const isMobile = window.innerWidth < 800;
        if ( isMobile ) {
            return (
                <div id="totaluri-judet-container" className="unselectable">
                    <div id="title">{this.props.jud.toUpperCase()}</div>
                    <div id="desc">Totaluri in baza de date pentru judet:</div>
                    <div id="anunturi">{ this.props.anunturiCount }  Anunturi</div>
                    <div id="licitatii">{ this.props.licitatiiCount } Licitatii</div>
                </div>
            )
        }
        else {
            return (
                <div id="totaluri-wrapper-desktop">
                    <div id="totaluri-judet-container-desktop">
                        <div id="title">{this.props.jud.toUpperCase()}</div>
                        <div id="desc">Totaluri in baza de date pentru judet:</div>
                        <div id="anunturi">{ this.props.anunturiCount }  Anunturi</div>
                        <div id="licitatii">{ this.props.licitatiiCount } Licitatii</div>
                    </div>
                </div>
            )
        }
    }
}

class Salvata extends React.Component {
    render() {
        if ( this.props.salvata === 1 ) {
            return (
                <span className="interesanta-smaller">I</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

class Raportata extends React.Component {
    render() {
        if ( this.props.raportata === 1 ) {
            return (
                <span className="raportata">R</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}


class LicitatiiMobil extends React.Component {

    getCssClassName(licitatieId) {
        let classes = "licitatie-row";
        if ( JudeteDataService.licitatiiAllSelected === licitatieId ) {
            classes += " selected";
        }
        return classes;
    }

    // Deschid direct pagina de detalii
    handleLicitatieClick(licitaiteSimplificata) {
        JudeteDataService.licitatiiAllSelected = licitaiteSimplificata.id;
        this.props.navigate("/id/" + licitaiteSimplificata.id );
    }

    render() {
        return (
            <div id="judete-container" className='unselectable' >
                
                <div id="header" className="licitatie-row unselectable">
                    <div onClick={ () => this.props.orderBy(ORDER_BY_ID) }>Id ↓</div>
                    <div className="all-licitatii-total-price-header" onClick={ () => this.props.orderBy(ORDER_BY_TOTAL_PRICE) }>Total ↓</div>
                    <div onClick={ () => this.props.orderBy(ORDER_BY_SURFACE) }>Surface↓</div>
                    <div onClick={ () => this.props.orderBy(ORDER_BY_MP_PRICE) }>P/mp ↓</div>
                    <div id="salvata-header"><Salvata salvata={1}/></div>
                </div>

                {this.props.licitatii.map( (licitatie, index) => (
                    <div key={index} onClick={() => this.handleLicitatieClick(licitatie)} >
                        <div className={this.getCssClassName(licitatie.id)}>
                            <div className='licitatie-id-smaller'>{licitatie.id}</div>
                            <div className='all-licitatii-total-price'>{licitatie.total_price}</div> 
                            <div>{licitatie.surface}</div> 
                            <div>{ parseFloat(licitatie.price_mp).toFixed(2)}</div> 
                            <div><Salvata salvata={licitatie.salvata}/></div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

// Sectiunea din pagina tuturor lictatiilor unui judet, care contine doar randurile inclusiv
// header-ul
class LicitatiiDesktop extends React.Component {

    getCssClassName(licitatieId) {
        let classes = "licitatie-row desktop";
        if ( JudeteDataService.licitatiiAllSelected === licitatieId ) {
            classes += " selected";
        }
        return classes;
    }

    // Deschid direct pagina de detalii
    handleLicitatieClick(licitatieSimplificata) {
        JudeteDataService.licitatiiAllSelected = licitatieSimplificata.id;
        this.props.navigate("/id/desktop/" + licitatieSimplificata.id );
    }

    render() {
        return (

            <div id="judete-grid-container-desktop">
                {/* Coloana 1 */}
                <div></div> 

                {/* Coloana 2 */}
                <div id="judete-container" className='unselectable' >

                    {/* Header */}
                    <div id="header" className="licitatie-row desktop">
                        <div id="header-id" onClick={ () => this.props.orderBy(ORDER_BY_TOTAL_PRICE) }>ID ↓</div>
                        <div onClick={ () => this.props.orderBy(ORDER_BY_TOTAL_PRICE) }>Total ↓</div>
                        <div onClick={ () => this.props.orderBy(ORDER_BY_SURFACE) }>Surface↓</div>
                        <div onClick={ () => this.props.orderBy(ORDER_BY_MP_PRICE) }>EUR/mp ↓</div>
                        <div><Salvata salvata={1}/></div>
                        <div><Raportata raportata={1}/></div>
                    </div>

                    {/* Randurile */}
                    {this.props.licitatii.map( (licitatie, index) => (
                        <div key={index} onClick={() => this.handleLicitatieClick(licitatie)} >
                            <div className={this.getCssClassName(licitatie.id)}>
                                <div className="id-wrapper">
                                    <div className='licitatie-id-smaller-desktop'>{licitatie.id}</div>
                                </div>
                                <div>{licitatie.total_price}</div> 
                                <div>{licitatie.surface}</div> 
                                <div>{ parseFloat(licitatie.price_mp).toFixed(2)}</div> 
                                <div><Salvata salvata={licitatie.salvata}/></div>
                                <div><Raportata raportata={licitatie.raportata}/></div>
                            </div>
                        </div>
                    ))}

                </div>

                {/* Coloana 3 */}
                <div></div> 

            </div>
        )
    }
}

// Pentru a putea folose functia useNavigate, am facut un wrapper function peste componenta LicitatiiMobil
function LictiatiiMobilWithNavigate(props) {
    let navigate = useNavigate();
    return <LicitatiiMobil {...props} navigate={navigate} licitatii={props.licitatiiAll} />
}

// Pentru a putea folose functia useNavigate, am facut un wrapper function peste componenta LicitatiiMobil
function LictiatiiDesktopWithNavigate(props) {
    let navigate = useNavigate();
    return <LicitatiiDesktop {...props} navigate={navigate} licitatii={props.licitatiiAll } />
}

// Corespunde paginii tuturor licitatiilor dintr-un judet
class AllLicitatiiJudet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            licitatiiAll: [],
            count: 0,
            jud: "judet"
        }
    }

    componentDidMount() {
        const judet = this.props.params.judet;

        this.setState({
            licitatiiAll: JudeteDataService.licitatiiAll,
            count: JudeteDataService.licitatiiAll.length,
            anunturiCount: JudeteDataService.licitatiiAllAnunturiCount,
            jud: JudeteDataService.licitatiiAllJud
        });

        // Daca nu am datele pregatite( am facut refresh ), fac request la server
        if ( !JudeteDataService.licitatiiAllJud ) {

            JudeteDataService.getRaportDataJudet(judet).then( response => {
                this.setState({
                    licitatiiAll: response.licitatii_all,
                    count: response.licitatii_all.length,
                    anunturiCount: response.anunturi_count,
                    jud: judet
                });

                JudeteDataService.licitatiiAll = response.licitatii_all;
                JudeteDataService.licitatiiAllJud = judet;
                JudeteDataService.licitatiiAllAnunturiCount = response.anunturi_count;
            });
        }

        // Salvez detele pentru a sti ce judet sa deschid in pagina principala
        JudeteDataService.dropJud = judet;

        window.scrollTo(0, 0);
    }

    orderBy(criteriu) {
        let sortedLicitatii;
        if ( criteriu === ORDER_BY_MP_PRICE ) {
            sortedLicitatii = this.state.licitatiiAll.sort( function( licitatieA, licitatieB ) {
                return licitatieA.price_mp - licitatieB.price_mp;
            });
        }
        
        if ( criteriu === ORDER_BY_SURFACE ) {
            sortedLicitatii = this.state.licitatiiAll.sort( function( licitatieA, licitatieB ) {
                return licitatieA.surface - licitatieB.surface;
            });
        }

        if ( criteriu === ORDER_BY_TOTAL_PRICE ) {
            sortedLicitatii = this.state.licitatiiAll.sort( function( licitatieA, licitatieB ) {
                return licitatieA.total_price - licitatieB.total_price;
            });
        }

        if ( criteriu === ORDER_BY_ID ) {
            sortedLicitatii = this.state.licitatiiAll.sort( function( licitatieA, licitatieB ) {
                return parseInt(licitatieA.id) - parseInt(licitatieB.id);
            });
        }

        this.setState({
            licitatiiAll: sortedLicitatii,
        })
    }

    render() {
        const isMobile = window.innerWidth < 800;
        if ( isMobile ) {
            return (
                <div>
                    <TotaluriJudet anunturiCount={this.state.anunturiCount} licitatiiCount={this.state.count} jud={this.state.jud} />
                    <LictiatiiMobilWithNavigate licitatiiAll={this.state.licitatiiAll} orderBy={(c) => this.orderBy(c)} />
                </div>
            )
        }
        else {
            return (
                <div>
                    <TotaluriJudet anunturiCount={this.state.anunturiCount} licitatiiCount={this.state.count} jud={this.state.jud} />
                    <LictiatiiDesktopWithNavigate licitatiiAll={this.state.licitatiiAll} orderBy={(c) => this.orderBy(c)} />
                </div>
            )
        }
    }
}

// eslint-disable-next-line
export default (props) => (
    <AllLicitatiiJudet {...props} params={useParams()} /> 
);
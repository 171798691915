import React from "react";
import { Text, Rect, Group } from 'react-konva';

import './CountersDesktop.css';

class CountersDesktop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anunturi: 0,
            licitatii: 0
        }
    }

    countersLocation(textLocationX, textLocationY) {
        return {
            rect: {
                X: textLocationX - 10,
                Y: textLocationY + 25
            },
            text: {
                X: textLocationX,
                Y: textLocationY + 32,
            }
        }
    }

    render() {
        return (
            <Group>
                <Rect x={this.countersLocation(this.props.x, this.props.y).rect.X} 
                    y={this.countersLocation(this.props.x, this.props.y).rect.Y} 
                    width={55} 
                    height={25} 
                    cornerRadius={5} 
                    stroke={"#B9726E"} 
                    strokeWidth={1} 
                    opacity={this.props.opacity} 
                    onClick={this.props.onClick}/> 

                <Text text={this.props.anunturi + "/" + this.props.licitatii} 
                    x={this.countersLocation(this.props.x, this.props.y).text.X} 
                    y={this.countersLocation(this.props.x, this.props.y).text.Y} 
                    fill="#E6D2D1" 
                    opacity={this.props.opacity}
                    onClick={this.props.onClick} />
            </Group>
        )
    }
}

export default CountersDesktop;
import React from 'react';
import JudeteDataService from '../services/JudeteDataService';
import { Link } from "react-router-dom";

import './licitatiiSelectateDesktop.css';

class Salvata extends React.Component {
    render() {
        if ( this.props.salvata === 1 ) {
            return (
                <span className="interesanta">I</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

class Raportata extends React.Component {
    render() {
        if ( this.props.raportata === 1 ) {
            return (
                <span className="raportata">R</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

class LicitatiiSelectateDesktop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            licitatiiSelectate: [],
            licitatiiAll: []
        }
    }

    componentDidMount() {
        // Incerc sa iau din cache in loc sa mai fac un nou request
        if (!!JudeteDataService.lastGetRaportDataJudet && JudeteDataService.lastGetRaportDataJudet.judet === this.props.judet) {
            this.setState({
                licitatiiSelectate: JudeteDataService.lastGetRaportDataJudet.licitatii_selectate,
                licitatiiAll: JudeteDataService.lastGetRaportDataJudet.licitatii_all
            });

            JudeteDataService.licitatiiAll = JudeteDataService.lastGetRaportDataJudet.licitatii_all;
            JudeteDataService.licitatiiAllAnunturiCount = JudeteDataService.lastGetRaportDataJudet.anunturi_count;
            JudeteDataService.licitatiiAllJud = this.props.judet;
        }
        else {
            /**
             * Response contine datele unui raport pentru un judet
             * judet = ""
             * licitatii_selectate  - [ {  id: 0, price_mp: 0, detalii: "", salvata: 1 sau 0, "raportata": 1 sau 0 }, ... ]
             * licitatii_all        - lista cu obiecte licitatii simplificate: LicitatieSimplificataDetails
             * anunturi_count       - numarul de anunturi din baza de date, pentru judetul selectat
             */
            JudeteDataService.getRaportDataJudet(this.props.judet).then( response => {
                this.setState({
                    licitatiiSelectate: response.licitatii_selectate,
                    licitatiiAll: response.licitatii_all
                });
                JudeteDataService.lastGetRaportDataJudet = response;
                JudeteDataService.licitatiiAll = response.licitatii_all;
                JudeteDataService.licitatiiAllJud = this.props.judet;
                JudeteDataService.licitatiiAllAnunturiCount = response.anunturi_count;
            });
        }
    }

    /**
     * Trimite catre ruta componentei pentru detaliile licitatiei functie de marimea ecranului
     * pentru a alege intre Licitie.js in cazul ecran de mobil sau LicitatieDesktop.js in cazul
     * in care se foloseste ecran de desktop 
     */
    urlToLicitatieComponent(l) {
        if ( window.innerWidth < 800 ) {
            return `/id/` + l.id;
        }
        else {
            return `/id/desktop/` + l.id;
        }
    }

    isLastSelected(licitatieId) {
        return parseInt(JudeteDataService.lastDropdownSelectedId) === parseInt(licitatieId);
    }
    
    render() {
        return(
            <div id="licitatii-selectate-container">
                <div id="jud-img-reserved"></div>

                <div id="licitatii-selectate-column">
                    <div id="licitatii-selectate">
                        <img id="close-jud-btn" alt='close button' src="/img/close-btn-x.svg" onClick={() => this.props.onClose() } />
                        <div id="selectii-label" >SELECTII {this.props.judet.toUpperCase()}</div>
                        { this.state.licitatiiSelectate.map( (l, index) => (

                            <Link id="link" key={index} to={ this.urlToLicitatieComponent(l) }>
                                <div className='licitatie-selectata desktop' { ...this.isLastSelected(l.id) && { style: {background: '#5f1000'}}}>
                                    <span className='licitatie-id' >{l.id}</span> 
                                    <span className="pret desktop">{ parseFloat(l.price_mp).toFixed(2) + " EUR/mp" }</span>
                                    <Salvata salvata={l.salvata}/>
                                    <Raportata raportata={l.raportata}/>
                                </div>
                            </Link>
                            
                        ))}
                    </div>
                </div>
                
            </div>
        )
    }
}

export default LicitatiiSelectateDesktop;
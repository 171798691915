import React from "react";

import LicitatieRow from "./LicitatieRow";

function LicitatiiList({licitatii}) {


    return (
        <div>
            { licitatii.map( (l, index) => {
                return <LicitatieRow key={index} licitatie={l}  /> 
            })}
        </div>
    );
}

export default LicitatiiList;
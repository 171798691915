import React from 'react';
import { Link } from "react-router-dom";

import './HomeButtons.css';

class HomeButtons extends React.Component {

    render() {
        return (
            <div id="buttons-container">
                <div className='home-button-wrap'>
                    <div id='contact-home-btn' className='home-button' style={{backgroundImage: "url(../../img/account.svg)"}} />
                </div>

                <div className='home-button-wrap'>
                    <Link to={`/search/mobile`}>
                        <div id='contact-home-btn' className='home-button' style={{backgroundImage: "url(../../img/search.svg)"}} />
                    </Link>
                </div>

                <div className='home-button-wrap'>
                    <Link to={`/`}>
                        <div id='anunturi-btn' className='home-button' style={{backgroundImage: "url(../../img/licitatii.svg)"}} />
                    </Link>
                </div>

                    <div className='home-button-wrap'>
                        <Link to={`/contact/mobile`}>
                            <div id='contact-home-btn' className='home-button' style={{backgroundImage: "url(../../img/contact.svg)"}} />
                        </Link>
                    </div>
            </div>
        );
    }
}

export default HomeButtons;
import React from 'react';

import './Message.css';

class Message extends React.Component {

    render() {

        if (this.props.show) {
            return (
                <div id="message-content">

                    <div id="inner-content">

                        <div id="message-text">{this.props.message}</div>

                        <div id='close-button' onClick={() => this.props.onClose() }>OK</div>

                    </div>


                </div>
            )
        }
        else {
            return ( <div></div>)
        }

    }

}

export default Message;
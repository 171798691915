import React from 'react';
import { Link, useParams } from "react-router-dom";

import JudeteDataService from '../services/JudeteDataService';
import Message from '../message/Message';
import unixTimeToDateString from '../services/Date'

import './Licitatie.css';

class ButtonSave extends React.Component {

    save( licitatieId ) {
        JudeteDataService.saveLicitatie(licitatieId).then( response => {
            if( response.added === 1) {
                this.props.onMessage("Salvata");
            }
            else {
                this.props.onMessage("A fost o eroare");
            }
        });

        this.props.onUpdateReqested();
    }

    removeSaved( licitatieId ) {
        JudeteDataService.removeLicitatie(licitatieId).then( response => {
            if ( response.removed === 1) {
                this.props.onMessage("Stearsa");
            }
            else {
                this.props.onMessage("A fost o eroare");
            }
        });
        this.props.onUpdateReqested();
    }

    render() {

        if ( this.props.salvata === 1 ) {
            return (
                <div id="save-btn" className="details-btn unselectable" onClick={() => { this.removeSaved(this.props.licitatieId) }}>Sterge</div>
            )
        }
        else {
            return (
                <div id="save-btn" className="details-btn unselectable" onClick={() => { this.save(this.props.licitatieId) }}>Interesanta</div>
            )
        }
    }
}

class ButonRaporteaza extends React.Component {

    raporteaza( licitatieId ) {
        JudeteDataService.raporteazaLicitatie(licitatieId).then( response => {
            if( response.added === 1) {
                this.props.onMessage("Raportata");
            }
            else {
                this.props.onMessage("A fost o eroare");
            }

            this.props.onUpdateReqested();
        });
    }


    render() {

        if ( this.props.raportata === 0 ) {
            return (
                <div id="report-btn" className="details-btn unselectable" onClick={() => { this.raporteaza(this.props.licitatieId) }}>Gresita</div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
}

class Licitatie extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            totalPrice: 0,
            priceMp: 0,
            localitate: "",
            message: "",
            pulled: 1
        }

    }

    componentDidMount() {
        const licitatieId  = this.props.params.licitatieId;

        JudeteDataService.getLicitatie(licitatieId).then( response => {
            this.setState({
                id: response.id,
                title: response.title,
                totalPrice: response.total_price,
                priceMp: response.price_mp,
                priceUnit: response.price_unit,
                surface: response.surface,
                judet: response.judet,
                localitate: response.localitate,
                dataScanare: unixTimeToDateString(response.data_scanare),
                dataLicitatie: response.data_licitatie,
                detalii: response.detalii,
                salvata: response.salvata,
                raportata: response.raportata,
                pdf: response.pdf,
                content: response.content,
                cf: response.cf,
                nrCadastru: response.nr_cadastru,
                pulled: response.pulled
            });

            // Salvez detele pentru a sti ce judet sa deschid in pagina principala
            JudeteDataService.dropJud = response.judet;

            // Salvez id-ul pentru a sti ce rand sa marchez in dropdown
            JudeteDataService.lastDropdownSelectedId = this.props.params.licitatieId;

            // Updatez si licitatiile din lista licitatiiAll daca este cazul
            const inList = JudeteDataService.licitatiiAll.find(l => l.id === response.id);
            if ( !!inList ) {
                inList.salvata = response.salvata;
            }

        }, reason => {
            console.error(reason);
        });
    }

    downloadPdf( id, url ) {
        let a  = document.createElement('a');
        a.href = url;
        a.download = id + '.pdf';
        a.click();
    }

    updateRequested() {
        JudeteDataService.lastGetRaportDataJudet = null;
        this.componentDidMount();
    }

    openDetaliiLink(detaliiLink) {
        window.open( detaliiLink );
    }

    showMessage(message) {
        this.setState({
            message: message
        });
    }

    closeMessage() {
        this.setState({
            message: ""
        });
    }

    pullData(licitatieId) {
        if ( this.state.pulled === 1) {
            // Inseamna ca deja am luat detaliile parsate pentru anuntul asta
            return;
        }

        this.setState({ pulled: 1 });

        JudeteDataService.pullData(licitatieId).then( response => {
            console.log(response);
            this.setState({
                content: response.content,
                dataLicitatie: response.data_licitatie,
                pdf: response.pdf,
                cf: response.cf,
                nrCadastru: response.nr_cadastru
            });
        });
    }

    /**
     * Pulled poate fi 1 = a fost parsata pagina de detalii
     * 0 = nu a fost parsata. Voi arata butonul dezactivat 
     * @param { pulled } pulledValue 
     */
    getCssClassForPullBtn( pulledValue ) {
        if ( pulledValue === 1) {
            return "pull-deactivate unselectable";
        }
        else {
            return "pull-active unselectable";
        }
    }

    elementDataLabel() {
        if ( !this.state.dataLicitatie || this.state.dataLicitatie.length === 0 ) {
            return null;
        }
        else {
            return ( <div className="property">Data</div> )
        }
    }

    elementDataValoare() {
        if ( !this.state.dataLicitatie || this.state.dataLicitatie.length === 0 ) {
            return null;
        }
        else {
            return ( <div className='value date-value'>{ this.state.dataLicitatie}</div>)
        }
    }

    elementLocalitate( localitate ) {
        return {
            label: function() {
                if ( !!localitate && localitate.length > 0 ) {
                    return ( <div className='property'>Loc</div> )
                }
                else {
                    return null;
                }
            },
            valoare: function() {
                if ( !!localitate && localitate.length > 0 ) {
                    return ( <div className='value'>{ localitate.toLowerCase() }</div> )
                }
                else {
                    return null;
                }
            }
        }
    }

    render() {

        return (
            <div id="content">

                {/* LISTA JUDETE BUTTON */}
                <Link id="link" to={`/`}><div  id="inapoi-btn">Lista judete</div></Link>

                {/* PULL DATA BUTTON */}
                <div id='pull-data-btn' className={this.getCssClassForPullBtn(this.state.pulled)} onClick={ () => this.pullData(this.state.id) }>Pull data</div>

                {/* TITLU */}
                <div id="title-licitatie" >
                    <div id="title-content-column">
                        <div id='title-text'>{this.state.title}</div>

                        {/* RAND CF SI NUMAR CADASTRU */}
                        <div id="rand-subtitle">
                            { !!this.state.cf && <div className="val-subtitle" >CF {this.state.cf.toUpperCase()}</div> }
                            { !!this.state.nrCadastru && <div className="val-subtitle" >Cadastru {this.state.nrCadastru.toUpperCase()}</div> }
                        </div>


                    </div>

                </div>


                {/* PROPRIETATILE */}
                <div id="properties">
                    <div className="property">Total price</div>
                    <div className='value'> { this.state.totalPrice } {this.state.priceUnit}</div>

                    <div className="property">Price MP</div>
                    <div className='value'> { parseFloat(this.state.priceMp).toFixed(2) }</div>

                    <div className="property">Surface </div>
                    <div className='value'>{ this.state.surface }</div>

                    <div className="property">Judet </div>
                    <div className='value'>{ this.state.judet }</div>

                    { this.elementLocalitate( this.state.localitate ).label() }
                    { this.elementLocalitate( this.state.localitate ).valoare() }

                    { this.elementDataLabel() }
                    { this.elementDataValoare() }

                    {/* Nu cred e folositor sa mai arat id-ul */}
                    {/* <div className='property'>Id</div> */}
                    {/* <div className='value'> { this.state.id }</div> */}

                </div>

                <div className="details-btn" onClick={() => {this.openDetaliiLink(this.state.detalii) }}>Deschide</div>

                { !!this.state.pdf && <div className="details-btn" onClick={() => {this.downloadPdf(this.state.id, this.state.pdf) }}>Publicatie</div> }

                <ButtonSave salvata={this.state.salvata} 
                    licitatieId={this.state.id} 
                    onUpdateReqested={() => this.updateRequested()} 
                    onMessage={(mes) => this.showMessage(mes)} />

                <ButonRaporteaza raportata={this.state.raportata} 
                    licitatieId={this.state.id} 
                    onUpdateReqested={() => this.updateRequested() } 
                    onMessage={(mes) => this.showMessage(mes)} />

                { !!this.state.content && <div id="details-content">{this.state.content}</div>}

                <Message message={this.state.message} 
                    show={this.state.message.length > 0} 
                    onClose={ () => this.closeMessage() } />


            </div>
        )
    }

}

// eslint-disable-next-line
export default (props) => (
    <Licitatie {...props} params={useParams()} /> 
);

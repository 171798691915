/**
 * Converteste secunde de la epoch in String de forma 24 Dec 2021
 * @param {int} unixtime Seconds from epoch
 * @returns {String}
 */
const unixTimeToDateString = function( unixtime ) {
    let date = new Date(unixtime * 1000);
    let day = date.getDate();
    let month = date.toLocaleDateString('en-US', { month: "short"});
    let year = 1900 + Number(date.getYear());
    return day + " " + month + " " + year;
};

export default unixTimeToDateString;
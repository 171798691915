import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import JudeteDataService from '../services/JudeteDataService';

import './Search.css';

// class Search extends React.Component {
function Search() {
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    useEffect( () => {
        setSearchValue( JudeteDataService.lastSearchInput );
    }, []);


    function handleClick() {
        // Sanitaze user input
        const searchText = searchValue.replace(/[^a-z0-9áéíóúñü .,_-]/gim, " ").trim();

        JudeteDataService.searchLicitatiiObs( searchText );
        navigate("/searchresults/mobile");
    }

    return (
        <div id="search-container">

            {/* LISTA JUDETE BUTTON */}
            <Link id="link" to={`/`}><div  id="inapoi-btn">Lista judete</div></Link>

            {/* Titlu */}
            <div id="title-cautare" >
                <div id="title-content-column">
                    <div id='title-text'>CAUTARE</div>
                </div>
            </div>


            {/* Input cautare */}
            <div id='row-cautare'>
                <div id="card-search-input" className='unselectable'>
                    <input id="search-input" type='text' placeholder="Ex: Bucuresti" value={searchValue} onChange={ (e) => setSearchValue(e.target.value )}/>
                </div>

            </div>

            <div id="search-btn" onClick={() => handleClick() }>Cauta</div>
        </div>
    )

}

export default Search;
import React from "react";

class Salvata extends React.Component {
    render() {
        if ( this.props.salvata === 1 ) {
            return (
                <span className="interesanta-smaller">I</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

export default Salvata;